<template>
  <v-card class="ma-2 pa-2">
    <v-layout class="pb-4">
      <v-btn tile color="primary" outlined @click="$router.push('/library/issue-book')"><v-icon>mdi-arrow-left</v-icon></v-btn>
      <v-card-title class="font-weight-black mx-2 py-0"> Library Setup </v-card-title>
      <v-spacer></v-spacer>
      <v-btn color="success" class="mr-4" @click="SaveUpdateHandler" tile> Save </v-btn>
    </v-layout>
    <v-divider class="pb-4"></v-divider>
    <v-form ref="form" v-model="valid">
      <v-row>
          <!-- <v-col cols="12" lg="3" md="4" sm="4">
            <v-text-field outlined dense hide-details label="Days (Per Book)" v-model="librarySettingObj.daysPerBook"></v-text-field>
          </v-col> -->
          <v-col cols="12" lg="3" md="4" sm="4">
            <v-text-field outlined dense hide-details label="Fine (Per Day)" v-model="librarySettingObj.finePerDay"></v-text-field>
          </v-col>
          <!-- <v-col cols="12" lg="3" md="4" sm="4">
            <v-text-field outlined dense hide-details label="Missing Book Fine" v-model="librarySettingObj.missingBookFinePercentage"></v-text-field>
          </v-col> -->
          <!-- <v-col cols="12" lg="3" md="4" sm="4">
            <v-text-field outlined dense hide-details label="Damage Book in ?" v-model="librarySettingObj.damageBookIn"></v-text-field>
          </v-col> -->
          <v-col cols="12" lg="4" md="4" sm="4">
            <v-text-field outlined dense hide-details label="Books Issue Limit (Per User)" v-model="librarySettingObj.booksIssueLimitPerUser"></v-text-field>
          </v-col>
          <!-- <v-col cols="12" lg="4" md="4" sm="4">
            <v-text-field outlined dense hide-details label="Books Request Limit (Per User)" v-model="librarySettingObj.booksReqLimitPerUser"></v-text-field>
          </v-col> -->
          <v-col cols="12" lg="4" md="4" sm="4">
            <v-text-field outlined dense hide-details label="Send Book Due Sms Before" v-model="librarySettingObj.daysBeforeDueSendNotification" suffix="days"></v-text-field>
          </v-col>
          <v-col cols="12" lg="12" md="6" sm="6">
            <v-textarea  outlined dense hide-details label="Sms Template" v-model="librarySettingObj.smsTemplate"></v-textarea>
            <p>(Add Chips to Show properties like student name in sms)</p>
          </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import momentTZ from 'moment-timezone';
export default {
  data () {
    return {
      reInit: 0,
      locationObj: {
        markers: [
          {
            id: 'm1',
            position: { lat: 51.47, lng: -0.09 },
            tooltip: 'tooltip for marker1',
            draggable: true,
            visible: true,
          }
        ]
      },
      payload: {
        showLapInfo: false,
        showConfiguration: false,
        allowMultipleMarker: false
      },
      appLogo: null,
      librarySettingObj: {},
      valid: false,
      logo: null,
      reRender: 0,
      timeZonesList: [],
      dateFormatsList: ['DD.MM.YYYY', 'YYYY-MM-DD', 'DD-MM-YYYY']
    }
  },
  components: {
  },
  mounted () {
    this.getLibrarySetting()
    this.timeZonesList = momentTZ.tz.names();
    this.appObj = this.appSettings ? this.$_copy(this.appSettings) : {}
  },
  methods: {
    getLibrarySetting () {
      this.$_execute('get', 'librarySetting').then(response => {
        this.librarySettingObj = response.data
      })
    },
    SaveUpdateHandler () {
      this.$_execute('post', 'librarySetting', this.librarySettingObj).then(() => {
        this.$root.$emit('snackbar', { message: 'Updated Successfully', color: 'success'})
      })
    }
  }
}
</script>
